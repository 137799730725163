import React from 'react';
import {StepsSection,HeadingLayout,Sectionheading,ImageLayout,TitleLayout,StepNumber,TextLayout,
    StepTitle,StepDesc,CtaLayout,CtaBtn
} from './steps.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Steps = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Steps{
                        SectionHeading

                        StepImg1{
                            childImageSharp{
                                fixed(height: 150){
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        StepTitle1
                        StepDesc1

                        StepImg2{
                            childImageSharp{
                                fixed(height: 150){
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        StepTitle2
                        StepDesc2

                        StepImg3{
                            childImageSharp{
                                fixed(height: 150){
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                        StepTitle3
                        StepDesc3
                        CtaBtnText
                        CtaBtnLink
                    }
                }
            }
    `);
        return (
        <StepsSection id="steps">
            <Container>
                <Row>
                    <Col md={12}>
                        <HeadingLayout>
                            <Sectionheading>
                            {JSONData.instituteJson.Steps.SectionHeading}
                            </Sectionheading>
                        </HeadingLayout>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <ImageLayout>
                            <GatsImg 
                                fixed={JSONData.instituteJson.Steps.StepImg1.childImageSharp.fixed} 
                                alt=""
                            />
                        </ImageLayout>
                        <TextLayout>
                            <TitleLayout>
                                <StepNumber>
                                    1
                                </StepNumber>
                                <StepTitle>
                                {JSONData.instituteJson.Steps.StepTitle1}
                                </StepTitle>
                            </TitleLayout>
                            <StepDesc>
                            {JSONData.instituteJson.Steps.StepDesc1}
                            </StepDesc>
                        </TextLayout>
                    </Col>
                    <Col lg={4}>
                        <ImageLayout>
                            <GatsImg 
                                fixed={JSONData.instituteJson.Steps.StepImg2.childImageSharp.fixed} 
                                alt=""
                            />
                        </ImageLayout>
                        <TextLayout>
                            <TitleLayout>
                                <StepNumber>
                                    2
                                </StepNumber>
                                <StepTitle>
                                {JSONData.instituteJson.Steps.StepTitle2}
                                </StepTitle>
                            </TitleLayout>
                            <StepDesc>
                            {JSONData.instituteJson.Steps.StepDesc2}
                            </StepDesc>
                        </TextLayout>
                    </Col>
                    <Col lg={4}>
                        <ImageLayout>
                            <GatsImg 
                                fixed={JSONData.instituteJson.Steps.StepImg3.childImageSharp.fixed} 
                                alt=""
                            />
                        </ImageLayout>
                        <TextLayout>
                            <TitleLayout>
                                <StepNumber>
                                    3
                                </StepNumber>
                                <StepTitle>
                                {JSONData.instituteJson.Steps.StepTitle3}
                                </StepTitle>
                            </TitleLayout>
                            <StepDesc>
                            {JSONData.instituteJson.Steps.StepDesc3}
                            </StepDesc>
                        </TextLayout>
                    </Col>
                </Row>
                <CtaLayout>
                <CtaBtn href={JSONData.instituteJson.Steps.CtaBtnLink} target='_self'>
                    {JSONData.instituteJson.Steps.CtaBtnText}
                    </CtaBtn>
                </CtaLayout>
            </Container>
        </StepsSection>
    );
}

export default Steps;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh6,Commonpara,SectionHeading,Ctabtn} from '../Common/common.style';

export const StepsSection = styled.section`
    padding:100px 0px 70px;
    background:#fafafa;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const Sectionheading = styled(SectionHeading)`

`;

export const ImageLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`

export const TextLayout = styled.div`
    padding:20px;
`;

export const TitleLayout = styled.div`
    display:flex;
    align-items:center;
`;

export const StepNumber = styled.h1`
    font-size:100px;
    color:#576b77;
    padding-right:30px;
`;

export const StepTitle = styled(Commonh6)`
    text-align:left;
    color:#576b77;
    font-weight:700;
`;

export const StepDesc = styled(Commonpara)`
    font-weight:500;
`;

export const CtaLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    padding-top:10px
`;

export const CtaBtn = styled(Ctabtn)`
    @media ${device.tablet} {
        font-size:20px;
        padding: 20px;
    }
`;



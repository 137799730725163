import React from 'react';
import {HowMuchSection,Sectionheading,HowMuchItem,HowMuchLayout,Title,Desc} from './howMuch.style';
import GatsImg from "gatsby-image";
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const HowMuch = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    HowMuch{
                        SectionHeading
                        HeadingImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        Title1
                        Desc1
                        Title2
                        Desc2
                    }
                }
            }
    `);
        return (
        <HowMuchSection id="howMuch">
            <Container>
                <Sectionheading>
                    {JSONData.instituteJson.HowMuch.SectionHeading}
                </Sectionheading>
                <GatsImg 
                    fluid={JSONData.instituteJson.HowMuch.HeadingImg.childImageSharp.fluid} 
                    alt=""
                    fadeIn
                />
                <HowMuchLayout>

                    <HowMuchItem>
                        <Title>
                            {JSONData.instituteJson.HowMuch.Title1}
                        </Title>
                        <Desc>
                            {JSONData.instituteJson.HowMuch.Desc1}
                        </Desc>
                    </HowMuchItem>

                    <HowMuchItem>
                        <Title>
                            {JSONData.instituteJson.HowMuch.Title2}
                        </Title>
                        <Desc>
                            {JSONData.instituteJson.HowMuch.Desc2}
                        </Desc>
                    </HowMuchItem>
                    
                </HowMuchLayout>
            </Container>
        </HowMuchSection>
    );
}

export default HowMuch;
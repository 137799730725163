import React from 'react';
import {BannerSection,BannerContents,BannerContentsLeft,BannerHeading,
    BannerDescription,ButtonLayout,CTABtn,NormalBtn,TextLayout,ResearcherText,RespondentText
} from './banner.style';
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Banner{
                        BannerHeading
                        BannerDescription
                        CtaBtnText
                        NormalBtnText
                        ResearcherText
                        RespondentText
                        RespondentLink
                        ResearcherLink
                    }
                }
            }
    `);
        return (
        <BannerSection id="home">
            <Container>
                <BannerContents>
                    <BannerContentsLeft>
                        <BannerHeading>
                            {JSONData.instituteJson.Banner.BannerHeading}
                        </BannerHeading>
                        <BannerDescription>
                            {JSONData.instituteJson.Banner.BannerDescription}
                        </BannerDescription>
                        <ButtonLayout>
                            <CTABtn href={JSONData.instituteJson.Banner.ResearcherLink} target='_self'>{JSONData.instituteJson.Banner.CtaBtnText}</CTABtn>
                            <NormalBtn href={JSONData.instituteJson.Banner.RespondentLink}>{JSONData.instituteJson.Banner.NormalBtnText}</NormalBtn>
                        </ButtonLayout>
                        <TextLayout>
                            <ResearcherText>{JSONData.instituteJson.Banner.ResearcherText}</ResearcherText>
                            <RespondentText>{JSONData.instituteJson.Banner.RespondentText}</RespondentText>
                        </TextLayout>
                    </BannerContentsLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
import React from 'react';
import {OptionsSection,OptionsLayout,OptionCard,OptionTitle,OptionDesc} from './options.style';
import GatsImg from "gatsby-image";
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Options = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Options{
                        HeadingImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        Items{
                            Title
                            Description
                        }
                    }
                }
            }
    `);
        return (
        <OptionsSection id="featuresContainer">
            <Container>
                <GatsImg 
                    fluid={JSONData.instituteJson.Options.HeadingImg.childImageSharp.fluid} 
                    alt=""
                />
                <OptionsLayout>
                    {
                        JSONData.instituteJson.Options.Items.map((item,idx) => {
                            return <OptionCard key={idx}>
                                        <OptionTitle>
                                            {item.Title}
                                        </OptionTitle>
                                        <OptionDesc>
                                            {item.Description}
                                        </OptionDesc>
                                    </OptionCard>
                        })
                    }
                </OptionsLayout>
            </Container>
        </OptionsSection>
    );
}

export default Options;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh5,Commonh6} from '../Common/common.style';

export const OptionsSection = styled.section`
    background-color:#fafafa;
    padding:20px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const OptionsLayout = styled.div`
    display:flex;
    align-items:initial;
    justify-content:space-between;
    text-align:center;

    @media ${device.laptop} {
        flex-direction:column;
    }
`

export const OptionCard = styled.div`
    margin:10px;
    padding:10px;
    background:#fff;
    box-shadow: 0px 0px 6px 1px #ececec;
    transition: all 0.3s ease-in-out;

    :hover {
        box-shadow: 0px 0px 6px 1px #87D8D8;
    }

    @media ${device.laptop} {
        margin-left:0px;
        margin-bottom:20px;
        padding:30px;

    }
`;

export const OptionTitle = styled(Commonh5)`
    font-weight:700;
    padding:30px 15px 0px;

`;

export const OptionDesc = styled(Commonh6)`
    font-weight:300;
    font-size:20px;
    padding-left:15px;

    @media ${device.tablet} {
        line-height: 1.5;

    }
`;
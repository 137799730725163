import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh5,} from '../Common/common.style';

export const PartnersSection = styled.section`
    background-color:#fff;
    padding:50px 0px 100px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const SectionHeading = styled(Commonh5)`
    color:#121949;
    padding-bottom:30px;
`;

export const PartnersLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-around;
    text-align:center;

    @media ${device.tablet} {
        flex-direction:column;
    }
`

export const Partner = styled.div`
    transition: all 0.3s ease-in-out;

    :hover{
        transform: scale(1.1)
    }

    @media ${device.tablet} {
        padding:30px;
    }
`;
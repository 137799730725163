import React from 'react';
import {PartnersSection,SectionHeading,PartnersLayout, Partner} from './partners.style';
import GatsImg from "gatsby-image";
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Partners = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                instituteJson{
                    Partners{
                        SectionHeading
                        Items{
                            Name
                            Image{
                                childImageSharp{
                                    fixed(width: 150){
                                    ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }

                    }
                }
            }
    `);
        return (
        <PartnersSection id="featuresContainer">
            <Container>
                <SectionHeading>
                    {JSONData.instituteJson.Partners.SectionHeading}
                </SectionHeading>
                <PartnersLayout>
                    {
                        JSONData.instituteJson.Partners.Items.map((item,idx) => {
                            return <Partner key={idx}>
                                        <GatsImg 
                                            fixed={item.Image.childImageSharp.fixed} 
                                            alt={item.name}
                                        />
                                    </Partner>
                        })

                    }
                </PartnersLayout>
            </Container>
        </PartnersSection>
    );
}

export default Partners;
import styled from 'styled-components';
import {device} from '../Common/device';
import {Ctabtn,Commonbtn,Commonh1,Commonh5,Commonh6} from '../Common/common.style';
import BackgroundImg from '../../../assets/images/banner.png';

export const BannerSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x: center;

    min-height: 100vh;
    padding:160px 0px 100px;
    display:flex;
    align-items:center;

    @media ${device.laptopM} {
        background-position-x: 70%;
    }
    @media ${device.laptopM} {
        background-position-x: 90%;
    }
    @media ${device.tablet} {
        background-position-x: 90%;
        padding:140px 10px 80px;
    }
`;

export const BannerContents = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
`;

export const BannerContentsLeft = styled.div`
    width:55%;

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
    }
`;

export const BannerHeading = styled(Commonh1)`
    color:#fff;
`; 

export const BannerDescription = styled(Commonh5)`
    font-size: 26px;
    width:80%;
    color:#fff;
    padding-bottom:50px;

    @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-top:30px;
        
    }
`;

export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-bottom:20px;
`;

export const CTABtn = styled(Ctabtn)`

    @media ${device.laptopM} {
        padding: 15px 20px;
    }
    
`;

export const NormalBtn = styled(Commonbtn)`
    
    @media ${device.laptopM} {
        padding: 15px 20px;
    }

`;

export const TextLayout = styled.div`
    display:flex;
    justify-content:space-between;

    @media ${device.tablet} {
        justify-content:center;
    }
    @media ${device.mobileL} {
        flex-direction:column;
    }
`;

export const ResearcherText = styled(Commonh6)`
    color:#fff;
    padding-right:60px;

    @media ${device.tablet} {
        font-size:1rem;
    }
`;

export const RespondentText = styled(Commonh6)`
    color:#fff;

    @media ${device.tablet} {
        font-size:0.9rem;
    }
`;
import React from "react";
import Banner from "../containers/Institute/Banner";
import Steps from "../containers/Institute/Steps";
import Partners from "../containers/Institute/Partners";
import Options from "../containers/Institute/Options";
import HowMuch from "../containers/Institute/HowMuch";
import HeaderMenu from "../containers/Institute/HeaderMenu";
import Footer from "../containers/Institute/Footer";
import GlobalStyle from "../containers/Institute/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Institute/Common/fonts"

const Institute = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <HeaderMenu/>
       <main>
            <Seo 
                title="Sistema de pesquisas | Pesquise com rapidez e qualidade"
                description="Pesquise com rapidez e qualidade"
            />
            <Banner/>
            <Steps/>
            <Partners/>
            <Options/>
            <HowMuch/>
            
        </main>
       <Footer/>
   </div>

)

export default Institute;
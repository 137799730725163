import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonh5,Commonh6,SectionHeading} from '../Common/common.style';

export const HowMuchSection = styled.section`
    background-color:#fff;
    padding:70px 0px 70px;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const Sectionheading = styled(SectionHeading)`
    padding-bottom:20px;
`;

export const HowMuchLayout = styled.div`
    display:flex;
    align-items:initial;
    justify-content:space-around;
    text-align:center;
`

export const HowMuchItem = styled.div`
    width:40%;
`

export const Title = styled(Commonh5)`
    font-weight:700;

    @media ${device.tablet} {
        font-size: 1rem;
    }
`;

export const Desc = styled(Commonh6)`
    font-weight:300;

    @media ${device.tablet} {
        line-height: 1.5;
        font-size: 0.9rem;
    }
`;